import React, { useState, useRef, useEffect } from "react";
import { useRoutes, useLocation, useParams } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";
import {
  AuthGet,
  AuthPost,
  AuthPut,
  AuthFile,
} from "../../common_var/httpService";
import { useStateValue } from "../../Context/StateProvider";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Toast from "../Toaster/Toast";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { accountNumber, name, routingNumber } from "../Regex/Regex";
import ActiveBankIcon from "../../assets/images/active_bank.svg";
import Plaid from "../../Components/Onboarding-Services/Plaid";
import { Flink } from "../../Components/Onboarding-Services/Flink";
import { DapiIntegration } from "../../Components/Onboarding-Services/Dapi";
import Banklookup from "../../Templates/bank-lookup/bank-lookup";
import ClouduploadIcon from "../../assets/images/cloudUpload_icon.svg";
import { useFormik } from "formik";

import SmalldeleteIcon from "../../assets/images/smallDelete_icon.svg";
const CustomModal = ({
  btnName,
  data,
  fun,
  step,
  openmodal,
  bank,
  setBank,
  getpayment,
  userid,
  getpayment1,
  redirectFrame,
  integration,
  setOpenmodal,
  closeplaid,
  setNextStep,
  goNextStep,
}) => {
  const { loanid } = useParams();
  console.log("sdsds", loanid);

  let user_id = sessionStorage.getItem("user_id");
  if (!user_id) {
    user_id = userid ? userid : sessionStorage.getItem("user_id");
    console.log("q2", user_id);
  }
  console.log("q2", user_id);

  let addbank = "Add new bank";
  let addbankurl = { url: `payment/addBank`, key: "borrower" };
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const commentsSubject = useRef("");
  const commentsComment = useRef("");
  const [{ isCosignorFlow }, dispatch] = useStateValue();
  const [doctype, setDoctype] = useState([]);
  const [file, setFile] = useState("");
  const [dueDate, setDueDate] = useState("");
  const procedureDate = useRef("");
  const [openmodalhere, setOpenmodalhere] = useState(false);
  const [showconnectway, setShowconnectway] = useState(false);
  const [opendoc, setOpendoc] = useState();
  const [upfile, setUpfile] = useState([]);
  const [filedoc, setFiledoc] = useState([]);
  const [sendbank, setSendbank] = useState(false);
  const [selectedprimaryaccount, setSelectedprimaryaccount] = useState(false);
  let Filedoc = [];
  const [dynamicClass, setDynamicClass] = useState(false);
  let changeactive = { url: `payment/setPrimary/${user_id}`, key: "borrower" };
  let getpaymethod = {
    url: `payment/list/${user_id}/${loanid}`,
    key: "borrower",
  };
  let getprocedureDate = JSON.parse(sessionStorage.getItem("procedure_date"));
  console.log("Get prodedure: ", getprocedureDate);
  let isLocalhost = process.env.REACT_APP_ISLOCALENV == "true" ? true : false;
  let url = window.location.href;
  console.log('url::: ', url);
  let splittedURL = url.split("//");
  console.log('splittedURL::: ', splittedURL);

  useEffect(() => {
    if (openmodal) {
      getpaymentfrommodal();
    }
  }, []);
  const [updateProcedureDate, setUpdateProcedureDate] = useState({
    procedure_startdate: getprocedureDate?.procedure_startdate,
    payment_duedate: getprocedureDate?.payment_duedate,
    loanamount: getprocedureDate?.loanamount,
  });

  const handleimage = (e) => {
    setDynamicClass(false);
    console.log(e.target.files);
    const dimg = [e.target.files[0]];
    setUpfile(e.target.files[0]);
    if (e.target.files[0]) {
      let data = {
        files: e.target.files[0],
        documentsTypes: "bankstatement",
      };

      setFiledoc([...filedoc, data]);
    } else {
      toast.error("please select a file");
    }
    e.target.value = "";
    console.log(dimg);
  };

  const removeDocument = (modified, index) => {
    console.log(index);
    // console.log(modified, name, index)
    // console.log(data.files.name.split(".")[0])
    // const name = data.files.name.split(".")[0]
    setFiledoc(filedoc.filter((item, i) => i != index));
  };

  const handledone = () => {
    setShowconnectway(false);
    console.log(selectedprimaryaccount);
    if (selectedprimaryaccount) {
      if (goNextStep) {
        stepupdate();
      } else {
        setOpenmodal(false);
        if (isLocalhost) {
          window.open(
            `${process.env.REACT_APP_UI_URL}/borrower/login`,
            "_self"
          );
        } else {
          window.open(
            `${splittedURL[0]}//${splittedURL[1].split(".")[0].split('-')[1]}.${
              process.env.REACT_APP_DOMAIN
            }/borrower/login`,
            "_self"
          );
        }
      }
    } else if (!selectedprimaryaccount) {
      toast.error("please select your primary account");
    } else {
      toast.error("please enter you bank details");
    }
  };
  const handleShow = () => {
    setShow(true);
    getprocedureDate = JSON.parse(sessionStorage.getItem("procedure_date"));
    console.log("Show: ", getprocedureDate?.procedure_startdate);
    setUpdateProcedureDate({
      procedure_startdate: getprocedureDate?.procedure_startdate,
      payment_duedate: getprocedureDate?.payment_duedate,
      loanamount: getprocedureDate?.loanamount,
    });
  };
  const handleClose = () => setShow(false);

  const loan_id = sessionStorage.getItem("loan_id");

  const handleComment = async () => {
    let user_id = sessionStorage.getItem("UserId");

    let commentsData = {
      subject: commentsSubject.current.value,
      comments: commentsComment.current.value,
      loan_id: data,
      user_id,
    };
    console.log("data: ", commentsData);
    await AuthPost(`opportunities/make-comment`, commentsData, "admin")
      .then((res) => {
        console.log("Comments", res);
        if (res.statusCode === 200) {
          toast.success("Comment added successfully");
          // dispatch({
          //     type: "TOAST",
          //     toastMessage: "Comment added successfully",
          //     toastType: "success",
          // });
          handleClose();
          fun();
        }
      })
      .catch((err) => {
        console.log("err--->", err);
        toast.error(err.message);
        // dispatch({
        //     type: "TOAST",
        //     toastMessage: err.message,
        //     toastType: "error",
        // });
      });
  };

  const dropDoctype = (e) => {
    console.log("Doc type: ", e.target.value);
    setDoctype(e.target.value);
  };

  const dropDueDate = (e) => {
    console.log("Due date type: ", e.target.value);
    setDueDate(e.target.value);
  };

  const handleFileChange = (e) => {
    console.log("Uploaded file: ", e.target.files[0]);
    setFile(e.target.files[0]);
  };

  const handleUploadDocument = async () => {
    console.log("Document type: ", doctype);
    console.log("File: ", file);
    let user_id = sessionStorage.getItem("UserId");
    const formData = new FormData();
    formData.append("type", doctype);
    formData.append("loan_id", data);
    formData.append("files", file);
    formData.append("user_id", user_id);
    console.log(formData, "Formdata");

    await AuthFile(`savefiles/uploads/`, formData, "admin")
      .then((res) => {
        // getDocs(id);
        if (res.statusCode === 200) {
          toast.success("File Uploaded");
          // dispatch({
          //     type: "TOAST",
          //     toastMessage: "File Uploaded",
          //     toastType: "success",
          // });
          handleClose();
          fun();
        }
        console.log("res---->", res);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message);
        // dispatch({
        //     type: "TOAST",
        //     toastMessage: err.message,
        //     toastType: "error",
        // });
      });
  };

  const handleApproveApplication = async () => {
    console.log("due date: ", dueDate);
    console.log("Procedure date: ", procedureDate.current.value);

    let dateData = {
      payment_duedate: dueDate,
      procedure_startdate: procedureDate.current.value,
    };

    await AuthPost(`loanstage/approve-loan/${loan_id}`, dateData, "admin")
      .then((res) => {
        if (res["statusCode"] == 200) {
          console.log("Res Data: ", res);
          navigate(`/admin/approved`);
          toast.success(`Application successfully moved to Deals`);
          // dispatch({
          //     type: "TOAST",
          //     toastMessage: `Application successfully moved to approved`,
          //     toastType: "success",
          // });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message);
        // dispatch({
        //     type: "TOAST",
        //     toastMessage: err.message,
        //     toastType: "error",
        // });
      });
  };

  const getpaymentfrommodal = async () => {
    await AuthGet(getpaymethod.url, "borrower")
      .then((res) => {
        console.log("djsjuakljs");
        console.log("get pay---->", res);
        if (res.data.statusCode == 403 || res.data.statusCode == 401) {
          toast.error(res.data.message);
        }
        let data = res.data.bankDetails;
        if (data.length == 1) {
          if (data[0].active_flag == "N") {
            changeBankAcct(data[0].id);
          }
        }

        data.map((ele) => {
          ele.maskedAccountNumber = showAccountNumber(ele.accountnumber);
          if (ele.active_flag == "Y") {
            setSelectedprimaryaccount(true);
          }
        });
        console.log("inside function", data);
        setBank(data);
        // setCard(res.data.cardDetails)
      })
      .catch((err) => {
        console.log("get pay ------>", err);
      });
  };

  const handleUpdateDate = async (e) => {
    setUpdateProcedureDate({
      procedure_startdate: e.target.value,
      payment_duedate: getprocedureDate?.payment_duedate,
      loanamount: getprocedureDate?.loanamount,
    });
  };

  const submitUpdateDate = async () => {
    console.log("Update date: ", updateProcedureDate);
    let dateData = {
      payment_duedate: updateProcedureDate?.payment_duedate,
      procedure_startdate: updateProcedureDate?.procedure_startdate,
    };
    console.log("Date data: ", dateData);
    await AuthPost(`loanstage/approve-loan/${loan_id}`, dateData, "admin")
      .then((res) => {
        if (res["statusCode"] == 200) {
          console.log("Res Data: ", res);
          sessionStorage.setItem(
            "procedure_date",
            JSON.stringify({
              procedure_startdate: updateProcedureDate?.procedure_startdate,
              payment_duedate: updateProcedureDate?.payment_duedate,
              loanamount: updateProcedureDate?.loanamount,
            })
          );
          handleClose();
          toast.success(`Procedure date updated`);
          // dispatch({
          //     type: "TOAST",
          //     toastMessage: `Procedure date updated`,
          //     toastType: "success",
          // });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message);
        // dispatch({
        //     type: "TOAST",
        //     toastMessage: err.message,
        //     toastType: "error",
        // });
      });
  };

  const handleConfirmProcedure = async () => {
    await AuthPost(`loanstage/add-loan-to-funded/${loan_id}`, {}, "admin")
      .then((res) => {
        if (res["statusCode"] == 200) {
          console.log("Res Data: ", res);
          handleClose();
          navigate(`/admin/fundingcontract`);
          toast.success(`Application successfully moved to Fund Disbursed`);
          // dispatch({
          //     type: "TOAST",
          //     toastMessage: `Application successfully moved to Funded`,
          //     toastType: "success",
          // });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message);
        // dispatch({
        //     type: "TOAST",
        //     toastMessage: err.message,
        //     toastType: "error",
        // });
      });
  };

  const formikCreateCounterOffer = useFormik({
    initialValues: {
      financialAmount: "",
      originationFee: "",
      interestRate: "",
      duration: "",
    },
    validationSchema: Yup.object({
      financialAmount: Yup.string().required("Financial Amount is required"),
      originationFee: Yup.string().required("Origination Fee is required"),
      interestRate: Yup.string().required("Interest Rate is required"),
      duration: Yup.string().required("Duration is required"),
    }),
    onSubmit: async (values) => {
      setShowconnectway(false);
      values.loan_id = data;
      values.financialAmount = parseInt(values.financialAmount);
      values.originationFee = parseInt(values.originationFee);
      values.interestRate = parseInt(values.interestRate);
      values.duration = parseInt(values.duration);
      console.log("Create counter offer values: ", values);

      await AuthPost(`offers/originate-offer/`, values, "admin")
        .then((res) => {
          if (res.statusCode == 200) {
            handleClose();
            fun();
            toast.success("Offer Created");
            // dispatch({
            //     type: "TOAST",
            //     toastMessage: "Offer Created",
            //     toastType: "success",
            // });
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error(err.message);
          // dispatch({
          //     type: "TOAST",
          //     toastMessage: err.message,
          //     toastType: "error",
          // });
        });

      values.financialAmount = "";
      values.originationFee = "";
      values.interestRate = "";
      values.duration = "";
    },
  });

  const addBank = useFormik({
    initialValues: {
      bankName: "",
      holderName: "",
      accountNumber: "",
      routingNumber: "",
      accountType: "",
      agree: false,
      confirm: false,
      user_id: user_id,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      bankName: Yup.string().required("Name of the Bank is required"),
      holderName: Yup.string().required(
        "Name of the Account Holder is required"
      ),
      accountNumber: Yup.string()
        .required("Bank Account Number is required")
        .matches(/^[0-9]{16}$/g, "Bank Account Number must be 16"),
      routingNumber: Yup.string()
        .required("Bank Routing Number is required")
        .matches(/^[0-9]{9}$/g, "Bank Rounting Number must be 9"),
      accountType: Yup.string().required("Bank account type is required"),
      agree: Yup.boolean().oneOf([true], "Please Accept Terms and Condition"),
      confirm: Yup.boolean().oneOf([true], "Please Confirm The Details"),
    }),
    onSubmit: async (values) => {
      //
      console.log("BANK", values);
      data = { ...values, loan_id: loanid };
      if (!sendbank) {
        toast.error("please upload your bank statements");
        return;
      }
      console.log("2", data);
      await AuthPost(addbankurl.url, data, addbankurl.key)
        .then((res) => {
          console.log("add--->", res);
          if (res.data != undefined) {
            if (res.data.statusCode >= 400 || res.data.statusCode <= 410) {
              toast.error(res.data.message[0]);

              // dispatch({
              //   type: "TOAST",
              //   toastMessage: res.data.message[0],
              //   toastType: "error",
              // });
            }
          }
          if (res.statusCode === 200) {
            setShowconnectway(false);
            setSendbank(false);
            toast.success("Bank Added Successfully");
            // if(res.bankaccount.length==1){
            // changeBankAcct(res.bankaccount.id)
            // }
            if (integration == "dapi") {
              getpayment1();
            } else {
              getpayment();
            }

            setOpenmodalhere(false);
            setBank({ done: "bankaddedmanually" });
            // getSystemGenerateOffers()
            // dispatch({
            //   type: "TOAST",
            //   toastMessage: "Bank Add successfully",
            //   toastType: "success",
            // });
            if (fun()) {
              fun();
            }

            handleClose();
          }
        })
        .catch((err) => {
          console.log("err--->", err);
        });
    },
  });

  const showAccountNumber = (number) => {
    let result = "";
    for (let i = 0; i < number.length - 4; i++) {
      result = result + "X";
    }
    console.log(result + number.substring(number.length - 4));
    return result + number.substring(number.length - 4);
  };

  let changeBankAcct = async (e) => {
    console.log("Bank.url->>>>>", changeactive.url);

    let data = {
      bank_id: e,
      loan_id: loanid,
    };
    console.log(data);
    await AuthPost(changeactive.url, data, changeactive.key)
      .then((res) => {
        console.log("bank update", res);
        if (res.statusCode === 200) {
          if (integration == "dapi") {
            getpayment1();
          } else {
            getpayment();
          }
          setSelectedprimaryaccount(true);
          toast.success(res.message[0]);
        }
      })
      .catch((err) => {
        console.log("err--->", err);
      });
  };

  let stepupdate = async () => {
    let values = {
      loan_id: loanid,
      current_step: step,
      frame:
        integration == "plaid"
          ? "Connect Your Bank-Plaid"
          : integration == "dapi"
          ? "Connect Your Bank-Dapi"
          : "",
      user_id: user_id,
    };
    await AuthPost("loan/update-step", values, "admin").then((res) => {
      if (res.statusCode == 200) {
        dispatch({
          type: "SET_STEP",
          payload: isCosignorFlow
            ? res.stepDt[0].active_cosignor_step
            : res.stepDt[0].step,
        });
        console.log(res);
        setOpenmodal(false);
        setNextStep(res["stepDt"][0]?.step);
        redirectFrame();
      } else {
        console.log(res.message);
      }
    });
  };

  const completed = async (values) => {
    let url = `document-vault/upload-documents`;
    let id = loanid;
    console.log(filedoc);
    const formData = new FormData();
    formData.append("loan_id", id);
    formData.append("user_id", user_id);
    if (filedoc.length > 0) {
      setDynamicClass(false);
      for (var i = 0; i < filedoc.length; i++) {
        formData.append("documentTypes[]", filedoc[i].documentsTypes);
        formData.append("files[]", filedoc[i].files);
      }
      console.log("fawwa", formData);
      await AuthFile(`${url}`, formData, "admin")
        .then((res) => {
          console.log(res);
          if (res.statusCode == 200) {
            setSendbank(true);
            setFiledoc([]);
            toast.success("Document Uploaded Sucessfully");
            // dispatch({
            //   type: "TOAST",
            //   toastMessage: "Document Uploaded Sucessfully",
            //   toastType: "success",
            // });
          }
          if (res.data != undefined) {
            if (res.data.statusCode >= 400 && res.data.statusCode <= 410) {
              toast.error(res.data.message);
              // dispatch({
              //   type: "TOAST",
              //   toastMessage: res.data.message,
              //   toastType: "error",
              // });
            }
          }
          console.log("res---->", res);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      toast.error("Please Upload a file");
      setDynamicClass(true);
    }
  };

  console.log("basok", bank);
  return (
    <>
      <Toast />
      <button className="emptyButton btn" onClick={handleShow}>
        {btnName}
      </button>

      {btnName === "Add new bank" ? (
        <>
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Add new bank account</Modal.Title>
            </Modal.Header>
            <div>
              <Modal.Body>
                <div>
                  <form
                    id="form"
                    // name="bankAddForm"
                    // onSubmit={addNewBank}
                    onSubmit={addBank.handleSubmit}
                  >
                    <div className="mb_24">
                      <label className="labelText">Name of the Bank</label>
                      <input
                        type="text"
                        placeholder="Enter Bank Name as per your account statement"
                        className="MainInput"
                        id="bankName"
                        name="bankName"
                        onChange={(e) => {
                          addBank.setFieldValue(
                            "bankName",
                            name(e.target.value)
                          );
                        }}
                        onBlur={addBank.handleBlur}
                        value={addBank.values.bankName}
                      />
                      {addBank.touched.bankName && addBank.errors.bankName ? (
                        <small className="requiredText">
                          {addBank.errors.bankName}
                        </small>
                      ) : null}
                    </div>
                    <div className="mb_24">
                      <label className="labelText">
                        Name of the Account Holder
                      </label>
                      <input
                        type="text"
                        placeholder=" Enter Your Name as per your account statement"
                        className="MainInput"
                        id="holderName"
                        name="holderName"
                        onChange={(e) => {
                          addBank.setFieldValue(
                            "holderName",
                            name(e.target.value)
                          );
                        }}
                        onBlur={addBank.handleBlur}
                        value={addBank.values.holderName}
                      />
                      {addBank.touched.holderName &&
                      addBank.errors.holderName ? (
                        <small className="requiredText">
                          {addBank.errors.holderName}
                        </small>
                      ) : null}
                    </div>
                    <div className="mb_24">
                      <label htmlFor="banktype">Bank Account type</label>
                      <select
                        placeholder="Account type"
                        className="adminInput form-application"
                        id="banktype"
                        name="accountType"
                        onChange={addBank.handleChange}
                        onBlur={addBank.handleBlur}
                        value={addBank.values.role}
                      >
                        <option defaultValue value="">
                          Select bank account
                        </option>
                        <option value={"Savings"}>Savings</option>
                        <option value={"Checking"}>Checking</option>
                      </select>
                      {addBank.touched.accountType &&
                      addBank.errors.accountType ? (
                        <small className="requiredText">
                          {addBank.errors.accountType}
                        </small>
                      ) : null}
                    </div>

                    <div className="mb_24">
                      <label className="labelText">Bank Account Number</label>
                      <input
                        type="text"
                        placeholder="Enter Your Account Number as per your account statement"
                        className="MainInput"
                        id="accountNumber"
                        name="accountNumber"
                        onChange={(e) => {
                          addBank.setFieldValue(
                            "accountNumber",
                            accountNumber(e.target.value)
                          );
                        }}
                        onBlur={addBank.handleBlur}
                        value={addBank.values.accountNumber}
                      />
                      {addBank.touched.accountNumber &&
                      addBank.errors.accountNumber ? (
                        <small className="requiredText">
                          {addBank.errors.accountNumber}
                        </small>
                      ) : null}
                    </div>
                    <div className="mb_24">
                      <label className="labelText">Bank Routing Number</label>
                      <input
                        type="text"
                        placeholder="Enter Your Bank Routing Number as per your account statement"
                        className="MainInput"
                        id="routingNumber"
                        name="routingNumber"
                        onChange={(e) => {
                          addBank.setFieldValue(
                            "routingNumber",
                            routingNumber(e.target.value)
                          );
                        }}
                        onBlur={addBank.handleBlur}
                        value={addBank.values.routingNumber}
                      />
                      {addBank.touched.routingNumber &&
                      addBank.errors.routingNumber ? (
                        <small className="requiredText">
                          {addBank.errors.routingNumber}
                        </small>
                      ) : null}
                    </div>

                    <div className="customCheckbox customCheckboxVerification mb_12">
                      <input
                        type="checkbox"
                        id="agreeterms"
                        name="agree"
                        onChange={addBank.handleChange}
                        onBlur={addBank.handleBlur}
                        value={addBank.values.agree}
                      />
                      <label htmlFor="agreeterms">
                        I hereby agree to the Terms & Conditions.{" "}
                      </label>
                      {addBank.touched.agree && addBank.errors.agree ? (
                        <small className="requiredText">
                          {addBank.errors.agree}
                        </small>
                      ) : null}
                    </div>

                    <div className="customCheckbox customCheckboxVerification mb_24">
                      <input
                        id="confrmBank"
                        type="checkbox"
                        className="mr30px"
                        name="confirm"
                        onChange={addBank.handleChange}
                        onBlur={addBank.handleBlur}
                        value={addBank.values.bankName}
                      />
                      <label htmlFor="confrmBank">
                        I hereby confirm that the Bank account details furnished
                        above are true to my knowledge.{" "}
                      </label>
                      {addBank.touched.confirm && addBank.errors.confirm ? (
                        <small className="requiredText">
                          {addBank.errors.confirm}
                        </small>
                      ) : null}
                    </div>
                    <div className="displayFlex justifyConentEnd">
                      <div className="mr_12">
                        <button
                          type="reset"
                          className="blue_outlinebtn mr_14 "
                          onClick={() => {
                            handleClose();
                            addBank.resetForm();
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                      <div className="">
                        <button type="submit" className=" blue_outlinebtn">
                          Add
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </Modal.Body>
            </div>
          </Modal>
        </>
      ) : btnName === "Add New Comment" ? (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Add New Comment</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <input
              type="text"
              placeholder="Subject"
              className="form-control inputField mb10px"
              ref={commentsSubject}
            />{" "}
            <br />
            <textarea
              name=""
              id=""
              cols="30"
              rows="10"
              placeholder="Comment"
              className="form-control inputField mb10px"
              ref={commentsComment}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleComment}>
              Add Comment
            </Button>
          </Modal.Footer>
        </Modal>
      ) : btnName === "Upload Documents" || opendoc ? (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Upload Documents</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <select
              name="documentType"
              onChange={dropDoctype}
              className="form-control inputField mb10px"
            >
              <option value="">Select Document Type</option>
              <option value="Government Issued ID Front">
                Government Issued ID Front
              </option>
              <option value="Government Issued ID Back">
                Government Issued ID Back
              </option>
              <option value="Paystub 1">Paystub 1</option>
              <option value="Paystub 2">Paystub 2</option>
              <option value="Bank Statement">Bank Statement</option>
              <option value="Income Document">Income Document</option>
              <option value="Driving License">Driving License</option>
              <option value="Other">Other</option>
            </select>
            <div className="form-control inputField mb10px">
              <label htmlFor="fileUpload">
                Upload file
                <input
                  onChange={handleFileChange}
                  type="file"
                  accept="image/png, application/pdf"
                  id="fileUpload"
                  hidden
                />
              </label>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleUploadDocument}>
              Upload
            </Button>
          </Modal.Footer>
        </Modal>
      ) : btnName === "Generate Deal" ? (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Generate Deal</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={formikCreateCounterOffer.handleSubmit}>
              <div>
                <div>
                  <label htmlFor="financialNumber">Financial Amount</label>
                  <input
                    type="number"
                    id="financialNumber"
                    name="financialAmount"
                    class="form-control inputField mb10px"
                    onChange={formikCreateCounterOffer.handleChange}
                    value={formikCreateCounterOffer.values.financialAmount}
                    onBlur={formikCreateCounterOffer.handleBlur}
                    placeholder="Enter Financial Amount"
                  />
                </div>
                <div>
                  <label htmlFor="interestRate">Interest Rate</label>
                  <input
                    type="number"
                    id="interestRate"
                    name="interestRate"
                    class="form-control inputField mb10px"
                    value={formikCreateCounterOffer.values.interestRate}
                    onChange={formikCreateCounterOffer.handleChange}
                    onBlur={formikCreateCounterOffer.handleBlur}
                    placeholder="Enter Interest Rate"
                  />
                </div>
                <div>
                  <label htmlFor="duration">Term</label>
                  <select
                    name="duration"
                    id="duration"
                    className="form-control inputField mb10px"
                    value={formikCreateCounterOffer.values.duration}
                    onChange={formikCreateCounterOffer.handleChange}
                    onBlur={formikCreateCounterOffer.handleBlur}
                  >
                    <option value="">Duration</option>
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="18">18</option>
                    <option value="24">24</option>
                  </select>
                </div>
                <div>
                  <label htmlFor="originationFee">Origination Fee</label>
                  <input
                    type="number"
                    id="originationFee"
                    name="originationFee"
                    class="form-control inputField mb10px"
                    value={formikCreateCounterOffer.values.originationFee}
                    onChange={formikCreateCounterOffer.handleChange}
                    onBlur={formikCreateCounterOffer.handleBlur}
                    placeholder="Enter Origination Fee"
                  />
                </div>
              </div>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Cancel
                </Button>
                <Button type="submit">Save</Button>
              </Modal.Footer>
            </form>
          </Modal.Body>
        </Modal>
      ) : btnName === "Approve" ? (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Financing Approval Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <label htmlFor="date">Procedure start date</label> <br />
              <input
                type="date"
                className="form-control inputField mb10px"
                ref={procedureDate}
              />
            </div>
            <label htmlFor="">Payment Due date</label>
            <select
              name="documentType"
              onChange={dropDueDate}
              className="form-control inputField mb10px"
            >
              <option value="">Select Payment Due date</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10">10</option>
              <option value="11">11</option>
              <option value="12">12</option>
              <option value="13">13</option>
              <option value="14">14</option>
              <option value="15">15</option>
              <option value="16">16</option>
              <option value="17">17</option>
              <option value="18">18</option>
              <option value="19">19</option>
              <option value="20">20</option>
              <option value="21">21</option>
              <option value="22">22</option>
              <option value="23">23</option>
              <option value="24">24</option>
              <option value="25">25</option>
              <option value="26">26</option>
              <option value="27">27</option>
              <option value="28">28</option>
              <option value="29">29</option>
              <option value="30">30</option>
              <option value="30">31</option>
            </select>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleApproveApplication}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
      ) : btnName === "Update Procedure Date" ? (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Financing Approval Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <label htmlFor="date">Procedure start date</label> <br />
              <input
                type="date"
                className="form-control inputField mb10px"
                onChange={(e) => handleUpdateDate(e)}
                value={updateProcedureDate?.procedure_startdate}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="primary" onClick={submitUpdateDate}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
      ) : btnName === "Confirm Procedure" ? (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              Are you sure you want to approve this application?
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                Procedure start date :
                <span> {updateProcedureDate?.procedure_startdate}</span>
              </div>{" "}
              <br />
              <div>
                Amount :<span> {updateProcedureDate?.loanamount}</span>
              </div>{" "}
              <br />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleConfirmProcedure}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
      ) : openmodal ? (
        <Modal show={openmodal} className="getstartedPopup">
          <Modal.Body>
            <div className="popheader">
              <div className="brand_logo"></div>
              <div></div>
            </div>

            <div>
              <h2>Select your primary bank account</h2>
            </div>
            <div className="infotable_wrapper">
              <table>
                <tbody>
                  {bank != null && bank.length > 0 ? (
                    bank.map((e, i) => {
                      console.log("s", e);
                      return (
                        <tr key={i}>
                          <th className="fontWight500 washedBlack_textClr">
                            {e.bankname == null
                              ? e.headername
                              : e.bankname + " " + e.maskedAccountNumber}
                          </th>
                          {e.active_flag == "N" ? (
                            <td
                              style={{ cursor: "pointer" }}
                              onClick={() => changeBankAcct(e.id)}
                            >
                              Set as primary
                            </td>
                          ) : (
                            <td className="">
                              <div className="displayFlex alignItemscenter gap6 justifyConentEnd">
                                <img src={ActiveBankIcon} />
                                <span className="spandexGreen_textClr">
                                  Active
                                </span>
                              </div>
                            </td>
                          )}
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={2}>
                        <h5 className="nodataText">No Bank Account Added</h5>
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td></td>
                    <td>
                      {" "}
                      <div className="">
                        {" "}
                        <button
                          className="emptyButton    text_blue "
                          onClick={() => {
                            setOpenmodalhere(true);
                          }}
                        >
                          + Add new bank
                        </button>
                      </div>
                    </td>
                  </tr>
                  <Modal
                    show={openmodalhere}
                    onHide={() => {
                      setOpenmodalhere(false);
                      setSendbank(false);
                    }}
                    size="lg"
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Add new bank account</Modal.Title>
                    </Modal.Header>
                    <div>
                      <Modal.Body>
                        <div>
                          <form
                            id="form"
                            // name="bankAddForm"
                            // onSubmit={addNewBank}
                            onSubmit={addBank.handleSubmit}
                          >
                            <div className="mb_24">
                              <label className="labelText">
                                Name of the Bank
                              </label>
                              <input
                                type="text"
                                placeholder="Enter Bank Name as per your account statement"
                                className="MainInput"
                                id="bankName"
                                name="bankName"
                                onChange={(e) => {
                                  addBank.setFieldValue(
                                    "bankName",
                                    name(e.target.value)
                                  );
                                }}
                                onBlur={addBank.handleBlur}
                                value={addBank.values.bankName}
                              />
                              {addBank.touched.bankName &&
                              addBank.errors.bankName ? (
                                <small className="requiredText">
                                  {addBank.errors.bankName}
                                </small>
                              ) : null}
                            </div>
                            <div className="mb_24">
                              <label className="labelText">
                                Name of the Account Holder
                              </label>
                              <input
                                type="text"
                                placeholder=" Enter Your Name as per your account statement"
                                className="MainInput"
                                id="holderName"
                                name="holderName"
                                onChange={(e) => {
                                  addBank.setFieldValue(
                                    "holderName",
                                    name(e.target.value)
                                  );
                                }}
                                onBlur={addBank.handleBlur}
                                value={addBank.values.holderName}
                              />
                              {addBank.touched.holderName &&
                              addBank.errors.holderName ? (
                                <small className="requiredText">
                                  {addBank.errors.holderName}
                                </small>
                              ) : null}
                            </div>
                            <div className="mb_24">
                              <label htmlFor="banktype">
                                Bank Account type
                              </label>
                              <select
                                placeholder="Account type"
                                className="adminInput form-application"
                                id="banktype"
                                name="accountType"
                                onChange={addBank.handleChange}
                                onBlur={addBank.handleBlur}
                                value={addBank.values.role}
                              >
                                <option defaultValue value="">
                                  Select bank account
                                </option>
                                <option value={"Savings"}>Savings</option>
                                <option value={"Checking"}>Checking</option>
                              </select>
                              {addBank.touched.accountType &&
                              addBank.errors.accountType ? (
                                <small className="requiredText">
                                  {addBank.errors.accountType}
                                </small>
                              ) : null}
                            </div>

                            <div className="mb_24">
                              <label className="labelText">
                                Bank Account Number
                              </label>
                              <input
                                type="text"
                                placeholder="Enter Your Account Number as per your account statement"
                                className="MainInput"
                                id="accountNumber"
                                name="accountNumber"
                                onChange={(e) => {
                                  addBank.setFieldValue(
                                    "accountNumber",
                                    accountNumber(e.target.value)
                                  );
                                }}
                                onBlur={addBank.handleBlur}
                                value={addBank.values.accountNumber}
                              />
                              {addBank.touched.accountNumber &&
                              addBank.errors.accountNumber ? (
                                <small className="requiredText">
                                  {addBank.errors.accountNumber}
                                </small>
                              ) : null}
                            </div>
                            <div className="mb_24">
                              <label className="labelText">
                                Bank Routing Number
                              </label>
                              <input
                                type="text"
                                placeholder="Enter Your Bank Routing Number as per your account statement"
                                className="MainInput"
                                id="routingNumber"
                                name="routingNumber"
                                onChange={(e) => {
                                  addBank.setFieldValue(
                                    "routingNumber",
                                    routingNumber(e.target.value)
                                  );
                                }}
                                onBlur={addBank.handleBlur}
                                value={addBank.values.routingNumber}
                              />
                              {addBank.touched.routingNumber &&
                              addBank.errors.routingNumber ? (
                                <small className="requiredText">
                                  {addBank.errors.routingNumber}
                                </small>
                              ) : null}
                            </div>

                            <div className="customCheckbox customCheckboxVerification mb_12">
                              <input
                                type="checkbox"
                                id="agreeterms"
                                name="agree"
                                onChange={addBank.handleChange}
                                onBlur={addBank.handleBlur}
                                value={addBank.values.agree}
                              />
                              <label htmlFor="agreeterms">
                                I hereby agree to the Terms & Conditions.{" "}
                              </label>
                              {addBank.touched.agree && addBank.errors.agree ? (
                                <small className="requiredText">
                                  {addBank.errors.agree}
                                </small>
                              ) : null}
                            </div>

                            <div className="customCheckbox customCheckboxVerification mb_24">
                              <input
                                id="confrmBank"
                                type="checkbox"
                                className="mr30px"
                                name="confirm"
                                onChange={addBank.handleChange}
                                onBlur={addBank.handleBlur}
                                value={addBank.values.bankName}
                              />
                              <label htmlFor="confrmBank">
                                I hereby confirm that the Bank account details
                                furnished above are true to my knowledge.{" "}
                              </label>
                              {addBank.touched.confirm &&
                              addBank.errors.confirm ? (
                                <small className="requiredText">
                                  {addBank.errors.confirm}
                                </small>
                              ) : null}
                            </div>

                            <div className="displayFlex justifyConentEnd mb_24">
                              <div className="mr_12">
                                <button
                                  type="reset"
                                  className="blue_outlinebtn mr_14 "
                                  onClick={() => {
                                    setOpenmodalhere(false);
                                    addBank.resetForm();
                                  }}
                                >
                                  Cancel
                                </button>
                              </div>
                              <div>
                                <button
                                  type="submit"
                                  className=" MainButton btn_Primary"
                                >
                                  Add
                                </button>
                              </div>
                            </div>
                          </form>
                          <div className="whiteFrame mb_24">
                            <div className="whiteFrameHeader">
                              <h3 className="heading18 fontWeight600">
                                Upload More
                              </h3>
                            </div>
                            <div className="p16">
                              <div className="textAlignCenter mb_24">
                                <label
                                  htmlFor="document_img"
                                  className={
                                    dynamicClass
                                      ? "label-file-upload_err"
                                      : "Chooseuploadbox"
                                  }
                                >
                                  <img
                                    src={ClouduploadIcon}
                                    className="mb_12"
                                  />
                                  <input
                                    id="document_img"
                                    name="image"
                                    type="file"
                                    onChange={(e) => handleimage(e)}
                                    accept=".jpg,.jpeg,.png,application/pdf"
                                    hidden
                                  />
                                  <h2>Click to upload</h2>
                                </label>
                              </div>
                              {filedoc?.length != 0 ? (
                                <div>
                                  <div className="spreadsheetWrapper mb_24">
                                    <table>
                                      <thead>
                                        <tr>
                                          <th>#</th>
                                          <th>File Name</th>
                                          <th>File Format</th>
                                          <th>File size</th>
                                          <th>Document Type</th>
                                          <th>Action</th>
                                        </tr>
                                      </thead>

                                      <tbody>
                                        {filedoc !== null &&
                                        filedoc.length > 0 ? (
                                          filedoc?.map((data, i) => {
                                            return (
                                              <tr key={i}>
                                                <td>
                                                  <div className="greenfolder"></div>
                                                </td>
                                                <td>
                                                  {
                                                    data.files.name.split(
                                                      "."
                                                    )[0]
                                                  }
                                                </td>

                                                <td>
                                                  {
                                                    data.files.name.split(
                                                      "."
                                                    )[1]
                                                  }
                                                </td>

                                                <td>
                                                  {(
                                                    data.files.size / 1024
                                                  ).toFixed(2)}{" "}
                                                  KB
                                                </td>

                                                <td>{data.documentsTypes}</td>
                                                <td>
                                                  <div className="uploadDocAction">
                                                    <button
                                                      className="deleteBtn"
                                                      onClick={() =>
                                                        removeDocument(
                                                          data.files.name,
                                                          i
                                                        )
                                                      }
                                                    >
                                                      <img
                                                        src={SmalldeleteIcon}
                                                      />{" "}
                                                      <span>Delete</span>
                                                    </button>
                                                  </div>
                                                </td>
                                              </tr>
                                            );
                                          })
                                        ) : (
                                          <tr>
                                            <td
                                              className="textAlignCenter"
                                              colSpan={5}
                                            >
                                              <h5 className="nodataText">
                                                No file selected
                                              </h5>
                                            </td>
                                          </tr>
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                  <div className="textAlignCenter">
                                    <button
                                      type="button"
                                      className="MainButton btn_Primary"
                                      onClick={() => {
                                        completed();
                                      }}
                                    >
                                      Upload
                                    </button>
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                              <div className="textAlignCenter"></div>
                            </div>
                          </div>
                        </div>
                      </Modal.Body>
                    </div>
                  </Modal>
                </tbody>
              </table>
              <div className="displayFlex  mb_24">
                <div>
                  <button
                    type="buttom"
                    className="blue_outlinebtn mr_14 "
                    onClick={() => {
                      handledone();
                    }}
                  >
                    Done
                  </button>
                </div>
                <div>
                  {!showconnectway ? (
                    <button
                      type="button"
                      className=" MainButton btn_Primary"
                      onClick={() => {
                        setShowconnectway(true);
                      }}
                    >
                      Add new bank via {integration ? integration : "plaid"}
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              {showconnectway ? (
                integration == "plaid" ? (
                  <Plaid
                    step={6}
                    setShowconnectway={setShowconnectway}
                    getpaymentfrommodal={getpaymentfrommodal}
                    setSelectedprimaryaccount={setSelectedprimaryaccount}
                  />
                ) : integration == "Flinks" ? (
                  <Flink
                    step={6}
                    setShowconnectway={setShowconnectway}
                    getpaymentfrommodal={getpaymentfrommodal}
                    setSelectedprimaryaccount={setSelectedprimaryaccount}
                  />
                ) : integration == "dapi" ? (
                  <DapiIntegration
                    step={6}
                    setShowconnectway={setShowconnectway}
                    getpaymentfrommodal={getpaymentfrommodal}
                    setSelectedprimaryaccount={setSelectedprimaryaccount}
                  />
                ) : (
                  ""
                )
              ) : null}
            </div>
          </Modal.Body>
        </Modal>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default CustomModal;
