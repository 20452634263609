import React, { useState, useEffect } from "react";
import PhoneValidator from "./PhoneValidator";
import { useStateValue } from "../../Context/StateProvider";
import PhoneNumberFormatter from "./Formatter";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";

const Phone = ({
  name,
  labelName,
  placeholder,
  required,
  defaultCountry,
  international,
  autoComplete,
  containerComponent,
  countries,
  countryCallingCodeEditable,
  addInternationalOption,
  countryOptionsOrder,
  countrySelectComponent,
  countrySelectProps,
  disabled,
  flagComponent,
  flagUrl,
  flags,
  focusInputOnCountrySelection,
  initialValueFormat,
  inputComponent,
  internationalIcon,
  limitMaxLength,
  labels,
  numberInputProps,
  locales,
  onCountryChange,
  onFocus,
  readOnly,
  ref,
  smartCaret,
}) => {
  const [isTouched, setIsTouched] = useState(false);
  const [{ errors, formData, formSubmitted, isFullPreview }, dispatch] = useStateValue();
  const handleChange = (e) => {
    dispatch({
      type: "SET_VALUES",
      payload: { [name]: e },
    });
  };
  return (
    <>
      <div className="col-md-6 mb_24">
        {errors[name] ? (
          <label htmlFor={name} className="labelText">
            {labelName} <span className="requiredText">{errors[name]}</span>
          </label>
        ) : (
          <label htmlFor={name} className="labelText">
            {labelName}{" "}
            {required ? <span className="requiredText">*</span> : <></>}
          </label>
        )}
        <PhoneInput
          onFocus={onFocus}
          readOnly={readOnly}
          ref={ref}
          smartCaret={smartCaret}
          locales={locales}
          numberInputProps={numberInputProps}
          onCountryChange={onCountryChange}
          internationalIcon={internationalIcon}
          labels={labels}
          limitMaxLength={limitMaxLength}
          initialValueFormat={initialValueFormat}
          inputComponent={inputComponent}
          flagUrl={flagUrl}
          flags={flags}
          focusInputOnCountrySelection={focusInputOnCountrySelection}
          countrySelectProps={countrySelectProps}
          disabled={disabled}
          flagComponent={flagComponent}
          countryOptionsOrder={countryOptionsOrder}
          countrySelectComponent={countrySelectComponent}
          addInternationalOption={addInternationalOption}
          autoComplete={autoComplete}
          countries={countries}
          countryCallingCodeEditable={countryCallingCodeEditable}
          containerComponent={containerComponent}
          defaultCountry={defaultCountry}
          international={international}
          id={name}
          placeholder={placeholder}
          name={name}
          className="mobileInput"
          value={formData[name]}
          onChange={(e) => handleChange(e)}
          onBlur={() => setIsTouched(true)}
          style={errors[name] ? { border: "1px solid #f44336" } : null}
        />
        {required && !isFullPreview ? (
          <div style={{ display: "none" }}>
            <PhoneValidator
              name={name}
              value={formData[name]}
              labelname={labelName}
              isTouched={isTouched}
              formSubmitted={formSubmitted}
              required={required}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default Phone;
