import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { AuthPost } from "../../common_var/httpService";

const SubmitApplication = ({ type, value,cosignor }) => {
  const loanid = sessionStorage.getItem('loan_id')
  const navigate = useNavigate();
  const params = useLocation();
  const checkCosignor = params.pathname.includes("cosignor");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let res = await AuthPost(
        `loan/submit-application`,
        { loanid: loanid ,
          cosignor: checkCosignor },
        "onboarding"
      );
      if (res.statusCode == 200) {
        navigate(`/thankyou`);
      }
    } catch (error) {
      console.log(error);
    }
  };


  return (
    <button
      type={type}
      onClick={(e) => handleSubmit(e)}
      className="MainButton btn_Primary mr_8"
    >
      {value}
    </button>
  );
};

export default SubmitApplication;
