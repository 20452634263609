import React, { useState, useRef, useEffect } from "react";
import {
  useRoutes,
  useLocation,
  useParams,
  useSearchParams,
  
} from "react-router-dom";
function SaltedgeConnect() {
  const queryParams = useLocation();
  const pathname = queryParams.pathname;
  const { loanid } = useParams()

  const [searchParams] = useSearchParams();
  console.log("pathname---->", queryParams.pathname);
  const [connectionid, setConnectionid] = useState("");
  const [connectbtn, setConnectbtn] = useState(false);
  const [frame, setFrame] = useState(false);
  const elem = useRef(null)
  useEffect(() => {
    debugger
    var pramstring = searchParams.get("connection_id");
    sessionStorage.setItem("connectionid", pramstring);
    var conget = sessionStorage.getItem("connectionid");
    var conset = "";
    console.warn(pramstring)
    if (pramstring || conget) {
      var paramk;
      if (pramstring) {
        paramk = pramstring;
      } else if (conget) {
        paramk = conget;
      }
      // if(paramk != null){
      //  setUrl(null)
      // }
    }
  }, [searchParams.get("connection_id")]);
  return (
    <div>Please save your bank details</div>
  )
}

export default SaltedgeConnect