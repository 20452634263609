import React from "react";

const Button = ({ type, value, handleClick, disabled, fId }) => {
  return (
    <div>
      <button
        disabled={disabled}
        type={type}
        onClick={(e) => handleClick(e, fId)}
        className="MainButton btn_Primary mr_8"
      >
        {value}
      </button>
    </div>
  );
};

export default Button;
