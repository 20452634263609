import React, { useEffect, useState } from "react";
import { useStateValue } from "../../Context/StateProvider";

const SSNValidator = ({ name, value, isTouched, labelName, required }) => {
  const [{ formData, formSubmitted, requiredFields }, dispatch] = useStateValue();

  useEffect(() => {
    if (isTouched || formSubmitted) {
      if (!value?.trim()) {
        dispatch({
          type: "SET_ERRORS",
          payload: { [name]: `is required` },
        });
      } else if (value.length < 9) {
        dispatch({
          type: "SET_ERRORS",
          payload: { [name]: `should be 9 characters` },
        });
      } else {
        dispatch({
          type: "REMOVE_ERROR",
          payload: name,
        });
      }
    }
  }, [value, isTouched, formData, formSubmitted]);

  useEffect(() => {
    dispatch({
      type: 'SET_VALUES',
      payload: {[name]: value ?? null}
    })

    if(required) {
      const isExist = requiredFields?.find(item => item.name === name);
      !isExist && dispatch({
        type: "SET_REQUIRED_FIELDS",
        payload: { name, Fieldtype: "ssn" } 
      })
    }
  }, [name]);

  return <></>;
};

export default SSNValidator;
