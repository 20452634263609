import React, { useEffect, useRef } from 'react';

function Metamap({ loanid, userId, save, fId }) {
  const button = useRef(null);

  useEffect(() => {
    if (button.current) {
      button.current.addEventListener('metamap:loaded', ({ detail }) =>
        console.log('[METAMAP] Loaded', detail)
      );

      button.current.addEventListener('metamap:userStartedSdk', ({ detail }) =>
        console.log('[METAMAP] User started verification', detail)
      );

      button.current.addEventListener(
        'metamap:userFinishedSdk',
        async ({ detail }) => {
          // if (fId) {
          //   save(fId);
          // } else {
          //   save();
          // }
          savePI();
        }
      );

      button.current.addEventListener('metamap:exitedSdk', ({ detail }) =>
        // console.log('[METAMAP] User exited verification', detail)
        savePI()
      );
    }
  }, [button]);

  const savePI = () => {
    if (fId) {
      save(fId);
    } else {
      save();
    }
  }

  return (
    <div>
      <metamap-button
        ref={button}
        style={{ display: 'none' }}
        clientid={process.env.REACT_APP_METAMAP_CLIENT_ID}
        flowid={process.env.REACT_APP_METAMAP_CLIENT_FLOW_ID}
        id="mati-button"
        color={'#505DED'} // to setup main color of buttons in your metamap
        textcolor={'#FFFFFF'} // to setup text color of buttons in your metamap
        metadata={`{"loan_id":"${loanid}", "user_id":"${userId}", "fixedLanguage":"en","webhookURL":"${process.env.REACT_APP_ADMIN_API_URL}/api/metamap/webhook"}`}
      ></metamap-button>
    </div>
  );
}

export default Metamap;
