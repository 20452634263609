export function hasNullValueInObject(obj, requiredFields) {
  for (const key in obj) {
    const item = requiredFields?.find((item) => item.name === key) || null;
    if (item?.Fieldtype === "checkbox") {
      if (obj[key]?.length === 0 && item !== null) {
        return false;
      }
    } else {
      if (obj[key] === null && item !== null) {
        return false;
      }
    }
  }
  return true;
}
