import React, { useState, useEffect } from "react";
import { useStateValue } from "../../Context/StateProvider";
import { EmailRegex } from "../Regex/Regex";

const EmailValidator = ({ name, value, isTouched, labelName, required }) => {
  const [{ formData, formSubmitted, requiredFields }, dispatch] = useStateValue();

  useEffect(() => {
    if (isTouched || formSubmitted) {
      if (!value && required) {
        dispatch({
          type: "SET_ERRORS",
          payload: { [name]: `is required` },
        });
      } else if (value?.length > 0) {
        if (!EmailRegex.test(String(value).toLowerCase()) && value) {
          dispatch({
            type: "SET_ERRORS",
            payload: { [name]: "Invalid" },
          });
        }
        else {
          dispatch({
            type: "REMOVE_ERROR",
            payload: name,
          });
        }
      } else {
        dispatch({
          type: "REMOVE_ERROR",
          payload: name,
        });
      }
    }
  }, [value, isTouched, formData, formSubmitted]);

  useEffect(() => {
    dispatch({
      type: 'SET_VALUES',
      payload: {[name]: value ?? null}
    })

    if(required) {
      const isExist = requiredFields?.find(item => item.name === name);
      !isExist && dispatch({
        type: "SET_REQUIRED_FIELDS",
        payload: { name, Fieldtype: "email" } 
      })
    }
  }, [name]);

  return <></>;
};

export default EmailValidator;
