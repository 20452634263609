import React, { useState } from "react";
import { useStateValue } from "../../Context/StateProvider";
import SSNValidator from "./SSNValidator";

const SSN = ({ name, labelName, placeholder, handleChange, required, readonly }) => {
  const [{ formData, errors, isFullPreview }, dispatch] = useStateValue();
  const [isTouched, setIsTouched] = useState(false);
  return (
    <div className="col-md-6 mb_24">
      {errors[name] ? (
        <label htmlFor={name} className="labelText">
          {labelName} <span className="requiredText">{errors[name]}</span>
        </label>
      ) : (
        <label htmlFor={name} className="labelText">
          {labelName}{" "}
          {required ? <span className="requiredText">*</span> : <></>}
        </label>
      )}
      <input autoComplete="off"
        maxLength={11}
        type="text"
        id={name}
        placeholder={placeholder}
        name={name}
        className="MainInput"
        onChange={(e) => handleChange(e)}
        onBlur={() => setIsTouched(true)}
        value={formData[name]?formData[name]?.length == 9 ? `✱✱✱-✱✱-${formData[name]?.replace(/[^\d]/g, "")?.slice(-4)}` : formData[name]?.replace(/[^\d]/g, ""):""}
        style={errors[name] ? { border: "1px solid #f44336" } : null}
        readOnly={readonly}
      />
      {required && !isFullPreview ? (
        <div style={{ display: "none" }}>
          <SSNValidator
            value={formData[name]}
            name={name}
            labelName={labelName}
            isTouched={isTouched}
            required={required}
          />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default SSN;
