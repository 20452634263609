import React from "react";
import { useStateValue } from "../../Context/StateProvider";

const DayPicker = ({ setDayPickerPopup, name }) => {
  const [{ formData }, dispatch] = useStateValue();
  const numbers = [
    { id: 1, value: 1 },
    { id: 2, value: 2 },
    { id: 3, value: 3 },
    { id: 4, value: 4 },
    { id: 5, value: 5 },
    { id: 6, value: 6 },
    { id: 7, value: 7 },
    { id: 8, value: 8 },
    { id: 9, value: 9 },
    { id: 10, value: 10 },
    { id: 11, value: 11 },
    { id: 12, value: 12 },
    { id: 13, value: 13 },
    { id: 14, value: 14 },
    { id: 15, value: 15 },
    { id: 16, value: 16 },
    { id: 17, value: 17 },
    { id: 18, value: 18 },
    { id: 19, value: 19 },
    { id: 20, value: 20 },
    { id: 21, value: 21 },
    { id: 22, value: 22 },
    { id: 23, value: 23 },
    { id: 24, value: 24 },
    { id: 25, value: 25 },
    { id: 26, value: 26 },
    { id: 27, value: 27 },
    { id: 28, value: 28 },
    { id: 29, value: 29 },
    { id: 30, value: 30 },
    { id: 31, value: 31 },
  ];

  const handleClick = (val) => {
    dispatch({
      type: "SET_VALUES",
      payload: { [name]: String(val) },
    });
    setDayPickerPopup(false);
  };

  return (
    <div
      style={{
        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
        maxWidth: "300px",
        borderRadius: "10px",
        padding: "0 0 5px 0",
      }}
    >
      <div
        style={{
          width: "100%",
          height: "40px",
          backgroundColor: "#246be8",
          marginBottom: "10px",
          display: "flex",
          justifyContent: "center",
          gap: "25px",
          paddingTop: "5px",
          borderRadius: "10px 10px 0 0",
        }}
      >
        <div
          style={{
            width: "25px",
            height: "25px",
            borderRadius: "50%",
            backgroundColor: "#fff",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              height: "35px",
              width: "15px",
              borderRadius: "10px",
              backgroundColor: "#3f5153",
              marginTop: "-15px",
            }}
          ></div>
        </div>
        <div
          style={{
            width: "25px",
            height: "25px",
            borderRadius: "50%",
            backgroundColor: "#fff",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              height: "35px",
              width: "15px",
              borderRadius: "10px",
              backgroundColor: "#3f5153",
              marginTop: "-15px",
            }}
          ></div>
        </div>
        <div
          style={{
            width: "25px",
            height: "25px",
            borderRadius: "50%",
            backgroundColor: "#fff",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              height: "35px",
              width: "15px",
              borderRadius: "10px",
              backgroundColor: "#3f5153",
              marginTop: "-15px",
            }}
          ></div>
        </div>
      </div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "auto auto auto auto auto auto auto",
          gridGap: "5px",
          padding: "0 10px 10px 10px",
        }}
      >
        {numbers.map((num) => (
          <button
            style={{
              border: "none",
              backgroundColor:
                formData[name] === String(num.value)
                  ? "#246be8"
                  : "transparent",
              padding: "5px",
              boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
              borderRadius: "3px",
              color: formData[name] === String(num.value) ? "white" : "black",
            }}
            type="button"
            key={num.id}
            onClick={() => handleClick(num.value)}
          >
            {num.value}
          </button>
        ))}
      </div>
    </div>
  );
};

export default DayPicker;
