import { useState } from "react";
import { CoborrowerModal } from "../Coborrower-modal/Coborrower-modal";
import { useStateValue } from "../../Context/StateProvider";

const CoBorrower = () => {
  const [{ isCosignorFlow }, dispatch] = useStateValue();
  const [showCoborrowerPopup, setShowCoborrowerPopup] = useState(false);
  const [hide, setHide] = useState(true);
  return (
    <>
      <div className="displayFlex mb_20">
        {isCosignorFlow === false && hide && (
          <button
            type="button"
            className="MainButton btn_Primary mr_8"
            value="Add co-borrower"
            onClick={() => setShowCoborrowerPopup(true)}
          >
            Add Coborrower
          </button>
        )}
      </div>
      <CoborrowerModal
        showCoborrowerPopup={showCoborrowerPopup}
        setShowCoborrowerPopup={setShowCoborrowerPopup}
        setHide={setHide}
      />
    </>
  );
};

export default CoBorrower;
