import React, { useState } from "react";
import EmailValidator from "./EmailValidator";
import { useStateValue } from "../../Context/StateProvider";

const Email = ({ name, handleChange, labelName, required, placeholder, readonly }) => {
  const [{ formData, errors }, dispatch] = useStateValue();
  const [isTouched, setIsTouched] = useState(false);
  return (
    <>
      <div className="col-md-6 mb_24">
        {errors[name] === "is required" ? (
          <label htmlFor={name} className="labelText">
            {labelName} <span className="requiredText">{errors[name]}</span>
          </label>
        ) : errors[name] === "Invalid" ? (
          <label htmlFor={name} className="labelText">
            <span className="requiredText">{errors[name]}</span> {labelName}
          </label>
        ) : (
          <label htmlFor={name} className="labelText">
            {labelName}{" "}
            {required ? <span className="requiredText">*</span> : <></>}
          </label>
        )}
        <input
          type="email"
          id={name}
          placeholder={placeholder}
          name={name}
          className="MainInput"
          onChange={(e) => handleChange(e)}
          onBlur={() => setIsTouched(true)}
          style={errors[name] ? { border: "1px solid #f44336" } : null}
          readOnly={readonly}
          value={formData[name]
            ?.replace(/[^a-zA-Z0-9-+@._\s]/g, "")
            ?.replace(/^[0-9]+/g, "")
            ?.replace(/\s/g, "")}
        />
        <div style={{ display: "none" }}>
          <EmailValidator
            required={required}
            value={formData[name]}
            name={name}
            labelName={labelName}
            isTouched={isTouched}
          />
        </div>
      </div>
    </>
  );
};

export default Email;
