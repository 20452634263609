import React from "react";
import { useEffect } from "react";
import axios from "axios";
import { Post } from "../../common_var/httpService";
import { useStateValue } from "../../Context/StateProvider";
function Argyle({ loanid, save, fId }) {
  const Argyle = window['Argyle'];

  useEffect(() => {
    argyle();
  }, []);

  const [{ integrationsStatus }, dispatch] = useStateValue();

  const argyle = async () => {
    await axios.post(
      'https://api-sandbox.argyle.com/v2/users',
      {},
      {
        auth: {
          username: process.env.REACT_APP_ARGYLE_API_KEY,
          password: process.env.REACT_APP_ARGYLE_API_SECRET,
        },
      }
    )
    .then((res) => {
      console.log("Success res::", res);
      const argyle = Argyle.create({
        linkKey: process.env.REACT_APP_ARGYLE_LINK_KEY,
        userToken: res?.data?.user_token,
        sandbox: true,
        onClose: () => {
          const newIntegrationsStatus = { ...integrationsStatus };
          newIntegrationsStatus.employmentVerification.argyle.activate = false;
          dispatch({
            type: 'CHANGE_SERVICE_STATUS',
            payload: { ...newIntegrationsStatus },
          });
        },
        onAccountConnected: async (payload) => {
          if (fId) {
            save(fId);
          } else {
            save();
          }
          const sendData = await Post(
            "api/sendArgyleData/" + loanid,
            payload,
            'admin'
          );
        },
        // onerror: () => {
        //   if (fId) {
        //     save(fId);  
        //   } else {
        //     save();
        //   }
        //   onclose();
        // },
        onUserCreated: ({ userToken }) => { },
      });
      argyle.open();
    })
    .catch((err) => {
      console.log("Capture err::", err);
      if (fId) {
        save(fId);
      } else {
        save();
      }
    });
  };
  return <></>;
}

export default Argyle;
