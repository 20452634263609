import React, { useEffect, useState } from "react";
import CirclecloseIcon from "../../assets/images/circleClose_icon.svg";
import Modal from "react-bootstrap/Modal";
import { AuthPost } from "../../common_var/httpService";
import { useStateValue } from "../../Context/StateProvider";

const ShuftiPro = ({ save, fId }) => {
  const [{ integrationsStatus }, dispatch] = useStateValue();
  const [show, setShow] = useState(true);
  const [verified, setVerified] = useState(false);
  const [id, setId] = useState("");

  useEffect(() => {
    window.addEventListener("message", async function (e) {
      if (e?.data?.verification_status == "verification.accepted") {
        if (e?.data?.request_id) {
          setId(e?.data?.request_id);
          setVerified(true);
        }
      }
    });
  }, []);

  const saveData = async () => {
    if (verified) {
      // var func = async () => {
        if (id) {
          await AuthPost(
            "api/shufti-pro-status",
            { data: id },
            "onboarding"
          ).then((res) => {
            console.warn("api call", res);
            if (res[0]?.data?.status == "verification.accepted") {
              if (fId) {
                save(fId);
              } else {
                save();
              }
              setVerified(false);
            }
          });
        }
      // };
      // func();
    }
  }

  useEffect(() => {
    console.log("verified", verified);
    saveData();
  }, [verified]);

  const handleClose = () => {
    setShow(false);
    const newIntegrationsStatus = { ...integrationsStatus };
    newIntegrationsStatus.identityVerification["shuftipro"].activate = false;
    console.log(newIntegrationsStatus);
    dispatch({
      type: "CHANGE_SERVICE_STATUS",
      payload: { ...newIntegrationsStatus },
    });
    if (fId) {
      save(fId);
    } else {
      save();
    }
  };
  return (
    <>
      <div className="shuftiproframe" style={{ width: "fit-content" }}>
        <Modal show={show} className="getstartedPopup" size="lg">
          <div className=" p16 popupcloseButton">
            <button className="emptyButton" onClick={handleClose}>
              <img src={CirclecloseIcon} />
            </button>
          </div>
          <Modal.Body>
            <iframe
              className="shuftipro_iframe"
              style={{ width: "100%", height: "640px" }}
              src="https://app.shuftipro.com/verification/journey/66okNtxS1695246207"
            ></iframe>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default ShuftiPro;
