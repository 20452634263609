import Spinner from "react-bootstrap/Spinner";

function SpinningLoader() {
  return (
    <>
      <Spinner animation="border" variant="secondary" />
    </>
  );
}

export default SpinningLoader;
