import { env_variables } from "../../common_var/env";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AuthGet, AuthPost } from "../../common_var/httpService";
import BanksImage from "../../assets/images/bank.png";
import Image from "../Image/Image";
import Button from "../Button/Button";
import { useStateValue } from "../../Context/StateProvider";
import Modal from "react-bootstrap/Modal";
import { useEffect, useState } from "react";
import SkipButton from "../SkipButton/SkipButton";
import CustomModal from "../Modal/Model";
import { toast } from "react-hot-toast";
import { SkipOnCode } from "../../common_var/common"; 
export const DapiIntegration = ({
  step,
  skip,
  goNextStep,
  setShowconnectway,
  getpaymentfrommodal,
  setSelectedprimaryaccount,
}) => {
  const [{ frames, currentStep, isCosignorFlow,globalStep }, dispatch] = useStateValue();
  const [shows, setShoww] = useState(false);
  const { loanid } = useParams();
  const navigate = useNavigate();
  const queryParams = useLocation();
  let url = window.location.href;
  const [bank, setBank] = useState([]);
  const [user_id, setUser_id] = useState("");
  const [openmodal, setOpenmodal] = useState(false);
  const [nextStep, setNextStep] = useState(step + 1);
  const [bankconnected, setBankconnected] = useState(false);
  const [errored,setErrored]=useState(null)
  let userid = sessionStorage.getItem("user_id");
  let isLocalhost = url.toString().includes("localhost") ? true : false;
  const params = queryParams.pathname.split("/");
  let getpaymethod = {
    url: `payment/list/${userid}/${loanid}`,
    key: "borrower",
  };
  let changeactive = { url: `payment/setPrimary/${user_id}`, key: "borrower" };

  const handleClose = () => {
    setShoww(false);
  };

  useEffect(() => {
    if (!getpaymentfrommodal) {
      bankcheck();
    }
    // if(bankconnected && !getpaymentfrommodal){
    //   setOpenmodal(true)

    // }
    userid = sessionStorage.getItem("user_id");
  }, []);

  let changeBankAcct = async (e) => {
    console.log("Bank.url->>>>>", changeactive.url);

    let data = {
      bank_id: e,
      loanid,
    };
    console.log("sasasas", data);
    await AuthPost(changeactive.url, data, changeactive.key)
      .then((res) => {
        console.log("bank update", res);
        if (res.statusCode === 200) {
          toast.success(res.message[0]);
          getpayment1();
        }
      })
      .catch((err) => {
        console.log("err--->", err);
      });
  };

  const getpayment1 = async () => {
    // debugger
    await AuthGet(getpaymethod.url, "borrower")
      .then((res) => {
        console.log("get pay---->", res);
        if (res.data.statusCode == 403 || res.data.statusCode == 401) {
          toast.error(res.data.message);
        }

        let data = res.data.bankDetails;
        if (data.length == 1) {
          if (data[0].active_flag == "N") {
            changeBankAcct(data[0].id);
          }
        }
        // debugger

        data.map((ele) => {
          ele.maskedAccountNumber = showAccountNumber(ele.accountnumber);

          if (ele.active_flag == "Y") {
            if (setSelectedprimaryaccount) {
              setSelectedprimaryaccount(true);
            }
          }
        });
        console.log("inside", data);
        setBank(data);
        // setCard(res.data.cardDetails)
      })
      .catch((err) => {
        console.log("get pay ------>", err);
      });
  };

  const showAccountNumber = (number) => {
    console.log("ldsoah", number);
    let result = "";
    for (let i = 0; i < number.length - 4; i++) {
      result = result + "X";
    }
    console.log(result + number.substring(number.length - 4));
    return result + number.substring(number.length - 4);
  };

  const getSystemGenerateOffers = async () => {
    let res = await AuthGet(
      `loan/originate-offer/${step}/${loanid}`,
      "onboarding"
    );
    if (res["statusCode"] == 200) {
      console.log("params", params);

      if (res["data"].length == 0) {
        let urlsplit = url.split("//");
        urlsplit = urlsplit[1].toString().split(".");

        if (params.length == 5) {
          setShoww(true);
          setTimeout(() => {
            isLocalhost
              ? navigate(`/${params[1]}`)
              : navigate(
                  `${urlsplit[1]}.${process.env.REACT_APP_DOMAIN}/${params[1]}`
                );
          }, 5000);
        } else {
          setShoww(true);
          setTimeout(() => {
            isLocalhost
              ? navigate(`/`)
              : navigate(`${urlsplit[1]}.${process.env.REACT_APP_DOMAIN}/`);
          }, 5000);
        }
        console.log("Invalid");
      }
      let data = res["data"];
      if (data.length > 0) {
        dispatch({
          type: "SET_OFFERS",
          payload: data,
        });
        dispatch({
          type: "SET_STEP",
          payload: isCosignorFlow
            ? res.data[0].active_cosignor_step
            : res.data[0].step,
        });

        const nextFrame = frames.filter((el) => el.step === currentStep + 1);
        dispatch({
          type: "SET_CURRENT_FRAME",
          payload: nextFrame[0]?.name,
        });
        // toast.success("Success");
      }
    } else {
      console.log("Invalid");
    }
  };

  var handler = window.Dapi.create({
    environment: window.Dapi.environments.sandbox, //either .sandbox or .production
    appKey: process.env.REACT_APP_DAPI_APPKEY,
    countries: ["AE", "US"], //Alpha-2 format for country codes
    isExperimental: false, //Recommended to keep this false, gives access to banks that are experimental / in beta
    onSuccess: (d) => {
      setErrored(null)
      postData(d);
      // .then(() => redirectFrame())
      // .catch(() => redirectFrame());
      console.log("dapi data::", d);
    }, //Capture the success
    onFailure: (e) => {
      setErrored(e);
      console.log(e);
      const body = {
        // user_id: userId,
        loan_id: loanid,
        service: "Dapi",
        frame: "bank_info",
        verification: "Bank Verification",
        response: JSON.stringify(e),
        status: false,
      };
      AuthPost("api/save-service", body, "onboarding");
    }, // Capture a failure
    onExit: function () {
      if(errored){
      SkipOnCode(loanid, globalStep, currentStep, frames , dispatch)
}
     
   },
  });

  const postData = async (bodyData) => {
    try {
      bodyData.loan_id = loanid;
      bodyData.step = step;
      const resp = await AuthPost("api/dapi", bodyData, "onboarding");
      if (resp.status === 200) {
        let data = resp.resData;
        data.map((ele) => {
          ele.maskedAccountNumber = showAccountNumber(ele.acno);
          if (ele.active_flag == "Y") {
            if (setSelectedprimaryaccount) {
              setSelectedprimaryaccount(true);
            }
          }
        });
        // setBank(data)
        setOpenmodal(true);
        // debugger
        console.log(resp);
        setUser_id(resp.user[0].user_id);
        sessionStorage.setItem("user_id", resp.user[0].user_id);

        if (getpaymentfrommodal) {
          getpaymentfrommodal();

          setShowconnectway(false);
        }

        console.log("Success::", resp);
        // setNextStep(resp["stepDt"][0]?.step);
      } else {
        toast.error(resp.message);
        //failure
        // redirectFrame(step+1);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const redirectFrame = () => {
    dispatch({
      type: "SET_STEP",
      payload: nextStep,
    });

    const nextFrame = frames.filter((el) => el.step === currentStep + 1);
    dispatch({
      type: "SET_CURRENT_FRAME",
      payload: nextFrame[0]?.name,
    });
  };
  const bankcheck = async () => {
    await AuthGet(`loan/fetch-bankconnect/${userid}`, "admin")
      .then((res) => {
        if (res.statusCode == 200) {
          if (!getpaymentfrommodal) {
            setOpenmodal(true);
          }
        }
      })
      .catch((err) => {
        console.log("get pay ------>", err);
      });
  };

  return (
    <>
      <div>
        <Image src={BanksImage} alt={"bank_banner"} classname="imagewidth" />
        <div>
          {setSelectedprimaryaccount || !skip ? "" : <SkipButton />}
          <Button
            type={"submit"}
            value={"Click to link your Bank "}
            handleClick={() => {
              handler.open();
            }}
          />
        </div>
      </div>

      <div>
        <Modal show={shows} onHide={handleClose}>
          <Modal.Header>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={{ fontSize: "20px", textAlign: "center" }}>
              Unfortunately, you don't have any offers.
            </div>
            <div style={{ fontSize: "16px", textAlign: "center" }}>
              Please contact {process.env.REACT_APP_DAPI_HELPMAIL}
            </div>
          </Modal.Body>
        </Modal>
        {openmodal ? (
          <CustomModal
            bank={bank}
            setBank={setBank}
            openmodal={openmodal}
            getpayment1={getpayment1}
            userid={user_id}
            redirectFrame={redirectFrame}
            setOpenmodal={setOpenmodal}
            goNextStep={goNextStep}
            integration={"dapi"}
            setNextStep={setNextStep}
            step={step}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
};
