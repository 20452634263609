import React, { useEffect, useState } from "react";
import { useStateValue } from "../../Context/StateProvider";
import NumberValidator from "./NumberValidator";

const Number = ({
  name,
  labelName,
  placeholder,
  minVal,
  maxVal,
  handleChange,
  handleClick,
  readOnly,
  required,
}) => {
  const [{ formData, errors, isFullPreview }, dispatch] = useStateValue();
  const [isTouched, setIsTouched] = useState(false);

  useEffect(() => {
    document.addEventListener("wheel", () => {
      if (document.activeElement.type === "number") {
        document.activeElement.blur();
      }
    });
  }, []);

  return (
    <>
      <div className="col-md-6 mb_24">
        {errors[name] ? (
          <label htmlFor={name} className="labelText">
            {labelName} <span className="requiredText">{errors[name]}</span>
          </label>
        ) : (
          <label htmlFor={name} className="labelText">
            {labelName}{" "}
            {required ? <span className="requiredText">*</span> : <></>}
          </label>
        )}
        <input
          type="number"
          name={name}
          id={name}
          value={
            formData[name] === 0
              ? 0
              : `${formData[name]}`.replace(/^(?!00[^0])0/, "")
          }
          className="MainInput"
          placeholder={placeholder}
          min={minVal}
          max={maxVal}
          onChange={(e) => handleChange(e)}
          onBlur={() => setIsTouched(true)}
          style={errors[name] ? { border: "1px solid #f44336" } : null}
          onClick={handleClick}
          readOnly={readOnly}
        />
        {required && !isFullPreview ? (
          <div style={{ display: "none" }}>
            <NumberValidator
              value={formData[name]}
              name={name}
              labelName={labelName}
              isTouched={isTouched}
              required={required}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default Number;
