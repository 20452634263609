export const EmailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

export const url = /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i

export const onlyAlphabet = /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/g

export const PasswordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?+&])[A-Za-z\d@$!%*?&+]{8,}$/;

export const SSNRegex = /^(?:[0-9]{3})(?:[0-9]{2})(?:[0-9]{4})$/;

export const onlyNumbers = (data) => data.replace(/[^0-9]/g, '');

export const onlyChar = (data, length) =>  {
  data = data.replace(/[^A-Za-z.]/g, '');
data = data.charAt(0).toUpperCase() + data.substr(1).toLowerCase();
return data
}

export const addPrefix = (data, prefix) => {
  data = data.replace(/[^0-9]/g, '');
  if (data.length > 0) {
    return prefix + data;
  } else if (data === prefix) {
    return '';
  }
  return '';
};

export const amount = (data, prefix) => {
  const amount = addPrefix(data, prefix);
  const whole = amount.slice(0, amount.length - 2);
  const decimal = amount.slice(amount.length - 2);

  if (!whole.length && !decimal.length) {
    return '';
  }
  return whole + '.' + decimal;
};

export const addSuffix = (data, suffix) => {
  if (
    data.length > 1 &&
    data.slice(data.length - 3, data.length - 1) !== suffix
  ) {
    if (data.length === 2) {
      return '';
    }
    return data.slice(0, data.length - 2) + suffix;
  } else if (data.length > 0) {
    data = data.replace(/[^0-9]/g, '');
    return data + suffix;
  }
};

export const expiry = (data) =>
  data
    .replace(/[^0-9]/g, '')
    .replace(/^([2-9])$/g, '0$1')
    .replace(/^(1{1})([3-9]{1})$/g, '0$1/$2')
    .replace(/^0{1,}/g, '0')
    .replace(/^([0-1]{1}[0-9]{1})([0-9]{1,2}).*/g, '$1/$2');

export const csv = (data) => data.replace(/[^0-9]/g, '').substring(0, 3);

export const handleLength = (data, length) => {
  if (data.length > length) {
    return data.substring(0, length);
  }
  return data;
};

export const cardNumber = (data) =>
  data
    .replace(/[^\dA-Z]/g, '')
    .replace(/(.{4})/g, '$1 ')
    .trim()
    .substring(0, 19);

export const accountNumber = (data) =>
  data.replace(/[^0-9]/g, '').substring(0, 16);

export const routingNumber = (data) =>
  data.replace(/[^0-9]/g, '').substring(0, 9);

export const name = (data) => {
  data = data.replace(/[^A-Za-z.]/g, '');
  data = data.charAt(0).toUpperCase() + data.substr(1).toLowerCase();
  return handleLength(data, 50);
};

export const mobile = (data) => { 
  data = data
    .replace(/\D+/g, '')
    .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
  return handleLength(data, 14);
};

export const zipcode = (data) => {
  data = onlyNumbers(data);
  return data
};
