import React, { useState, useEffect } from "react";
import { useStateValue } from "../../Context/StateProvider";
import TextValidator from "./TextValidator";

const Text = ({
  name,
  handleChange,
  labelName,
  placeholder,
  maxLen,
  required,
  trim,
  readonly,
}) => {
  const [{ formData, errors, isFullPreview }, dispatch] = useStateValue();
  const [isTouched, setIsTouched] = useState(false);
  const alphaOnly =
    name == "firstname" ||
    name == "lastname" ||
    name == "middle_initial" ||
    name == "coSignor_firstname" ||
    name == "coSignor_middle_initial" ||
    name == "coSignor_lastname";

  const commaFormatField =
    name == "monthly_income" ||
    name == "monthly_mortgage" ||
    name == "additional_income" ||
    name == "annual_income" ||
    name == "coSignor_additional_income" ||
    name == "coSignor_annual_income" ||
    name == "coSignor_monthly_income" ||
    name == "coSignor_monthly_mortgage";

  const numbersOnly = name == "zipcode" || name == "duration" || name == "year";

  const noSpacesOnly =
    name == "unit" ||
    name == "city" ||
    name == "employer_name" ||
    name == "jobtitle";

  return (
    <>
      <div className="col-md-6 mb_24">
        {errors[name] === "Invalid" ? (
          <label htmlFor={name} className="labelText">
            <span className="requiredText">{errors[name]}</span> {labelName}
          </label>
        ) : errors[name] ? (
          <label htmlFor={name} className="labelText">
            {labelName} <span className="requiredText">{errors[name]}</span>
          </label>
        ) : (
          <label htmlFor={name} className="labelText">
            {labelName}{" "}
            {required ? <span className="requiredText">*</span> : <></>}
          </label>
        )}
        <input
          maxLength={maxLen}
          type="text"
          id={name}
          placeholder={placeholder}
          name={name}
          className="MainInput"
          onChange={(e) => {
            if (commaFormatField) {
              e.target.value = e.target.value
                ?.toString()
                .replace(/[^0-9]/g, "");
              handleChange(e);
            } else if (alphaOnly) {
              e.target.value = e.target.value?.replace(/[^a-zA-Z]/gi, "");
              handleChange(e);
            } else {
              handleChange(e);
            }
          }}
          onBlur={() => setIsTouched(true)}
          value={
            formData[name] && numbersOnly
              ? `${formData[name]?.replace(/[^0-9]/g, "")}`
              : formData[name] && alphaOnly
              ? `${formData[name]?.replace(/[^a-zA-Z]/gi, "")}`
              : formData[name] && noSpacesOnly
              ? `${formData[name]
                  ?.replace(/[^a-zA-Z0-9 ]/g, "")
                  .replace(/^[ ]/g, "")}`
              : formData[name] &&
                (name == "city" ||
                  name == "jobtitle" ||
                  name == "coSignor_jobtitle" ||
                  name == "coSignor_city")
              ? `${formData[name]?.replace(/[^a-zA-Z ]/gi, "")}`
              : formData[name] && commaFormatField
              ? Intl.NumberFormat("en-US").format(formData[name])
              : formData[name]
              ? `${formData[name]}`
              : ""
          }
          style={errors[name] ? { border: "1px solid #f44336" } : null}
          readOnly={readonly}
        />
        {!isFullPreview && (
          <div style={{ display: "none" }}>
            <TextValidator
              required={required}
              maxLen={maxLen}
              value={formData[name]}
              name={name}
              labelName={labelName}
              isTouched={isTouched}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default Text;
