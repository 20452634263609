import { useEffect } from "react";
import { useRoutes, useLocation } from "react-router-dom";
import "./styles.scss";
import Landing from "./Pages/StartPage/Landing";
import Sales from "./Sales/Sales";
import Thankyou from "./Pages/Thankyou/Thankyou";
import Toast from "./Components/Toaster/Toast";
import { Get } from "./common_var/httpService";
import { useStateValue } from "./Context/StateProvider";
import BankLookupContainer from "./Templates/bank-lookup-container/bank-lookup-container";
import ContractSignature from "./Templates/ContractSignature/ContractSignature";
import Offers from "./Templates/Offers/Offers";
import { io } from "socket.io-client";
import SaltedgeConnect from "./Pages/Saltedge/SaltedgeConnect"
function App() {
  const queryParams = useLocation();
  const [{ socket}, dispatch] = useStateValue();


  const  getCurrency = async () => {
    await Get('api/currency','admin')
          .then((res)=>{
              dispatch({
                type: 'SET_CURRENCY',
                payload: res?.currency?.symbol,
                });

          })
           .catch((err) => {
            console.log(err);
          });
  }

  const connectSocket = () => {
    let socket = io(process.env.REACT_APP_SOCKET_SERVER, {
      reconnection: false,
    });
    dispatch({
      type: "SET_SOCKET",
      payload: socket,
    });
    socket?.emit("newUser", sessionStorage.getItem("UserId"));
  };

  const checkExpiry = (token) => {
    const expiry = token ? JSON.parse(atob(token.split(".")[1])).exp : null;
    return expiry > Math.floor(new Date().getTime() / 1000);
    // console.log('expiry::: ', expiry);
    // console.log(expiry > Math.floor(new Date().getTime() / 1000))
  };

  if (
    sessionStorage.getItem("login") == "true" &&
    (socket === null || socket === undefined)
  ) {
   
     let token = sessionStorage.getItem("borrower_token");
    
    if (token && checkExpiry(token)) {
      console.log("Reconnecting................. socket!");
      connectSocket();
    }
  }
  useEffect(() => {
    if (queryParams?.pathname.includes("onboarding")) {
      document.body.classList.add("landing-background");
    } else {
      document.body.classList.remove("landing-background");
    }
    getCurrency();
  }, []);

  const routes = useRoutes([
    // { path: "/setpassword/:token/:id", element: <SetBorrowerPassword /> },
    { path: "/", element: <Landing /> },
    { path: "/thankyou", element: <Thankyou /> },
    {
      path: "/onboarding/bank-lookup/:loanid/:token",
      element: <BankLookupContainer />,
    },
    { path: "/:providername/onboarding/:loanid/:token", element: <Sales /> },
    { path: "/onboarding/:loanid/:token", element: <Sales /> },
    { path: "/cosignor/onboarding/:loanid/:token", element: <Sales /> },
    // { path: "/cosignor/onboarding/:loanid/:token", element: <CoBorrowerOnboarding /> },
    { path: "/full-view/:loanid", element: <Sales /> },
    { path:"/saltwaiting",element:<SaltedgeConnect/>},
    {
      path: "/submit-application/:loanid/:token",
      element: <Offers step={8} />,
    },
    {
      path: "/acceptance/:loanid/:token",
      element: <ContractSignature step={9} />,
    },

    // {
    //   element: <OnboardingGuard />,
    //   children: [
    //     // { path: "/selectprovider", element: <SelectDealer /> },
    //     { path: "/:providername/onboarding/:loanid/:token", element: <Sales /> },
    //     { path: "/onboarding/:loanid/:token", element: <Sales /> },
    //   ],
    // },
  ]);
  return (
    <>
      <Toast />
      {routes}
    </>
  );
}

export default App;
