import React from "react";
import { AuthPost } from "../../common_var/httpService";
import { useStateValue } from "../../Context/StateProvider";
import { useParams } from "react-router-dom";
import Button from "../Button/Button";

const SkipButton = () => {
  const { loanid } = useParams();
  const [{ globalStep, currentStep, frames }, dispatch] = useStateValue();
  const step = currentStep + 1;

  const handleClick = async (e) => {
    try {
      e.preventDefault();

      if (globalStep < step) {
        const res = await AuthPost(
          `api/skip/${step}/${loanid}`,
          {},
          "onboarding"
        );
        if (res.statusCode === 200) {
          dispatch({
            type: "SET_STEP",
            payload: step,
          });
          dispatch({
            type: "SET_CURRENT_STEP",
            payload: step,
          });
          const nextFrame = frames.filter((el) => el.step === step);
          dispatch({
            type: "SET_CURRENT_FRAME",
            payload: nextFrame[0]?.name,
          });
        }
      } else {
        dispatch({
          type: "SET_CURRENT_STEP",
          payload: step,
        });
        const nextFrame = frames.filter((el) => el.step === step);
        dispatch({
          type: "SET_CURRENT_FRAME",
          payload: nextFrame[0]?.name,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Button
        type={"button"}
        value={"Skip"}
        handleClick={handleClick}
        disabled={false}
      />
    </div>
  );
};

export default SkipButton;
